import { useEffect, useRef } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  Row,
  Col,
  Form,
  Badge,
  Button,
  Tooltip,
  Collapse,
  OverlayTrigger
} from "react-bootstrap";
import USPAccordion from "./usp-accordion";
import { Legend, FieldSet } from "../common-styling";
import TextArea from "../reusable-form-fields/text-area";
import FileInput from "../reusable-form-fields/input-file";
import TextField from "../reusable-form-fields/text-field";
import RadioInput from "../reusable-form-fields/input-radio";
import { colorCodeRegex } from "../../services/common-service";
import { FOOTER_FOREGROUND, FUNNEL_DESIGNS, THUMBNAIL_TYPE } from "../strings";
import ColorPickerWithTextField from "../reusable-form-fields/color-picker-with-text-field";
import { useTranslation } from "react-i18next";

const LayoutSection = ({ resourceId, parentFolderName, selectedCategory }) => {
  const { t } = useTranslation();
  const baseColorPickerRef = useRef("");
  const modernBackgroundOneRef = useRef("");
  const modernBackgroundTwoRef = useRef("");
  const modernContrastColorRef = useRef("");
  const modernHeaderLabelsColorRef = useRef("");
  const modernNextButtonTextColorRef = useRef("");
  const modernSubmitButtonTextColorRef = useRef("");
  const modernSubmitButtonHoverBackgroundRef = useRef("");
  const modernSubmitButtonBackgroundRef = useRef("");
  const modernNextButtonBackgroundRef = useRef("");
  const modernNextButtonHoverBackgroundRef = useRef("");
  const companyLogoColorPickerRef = useRef("");
  const additionalTaglineColorRef = useRef("");
  const additionalTaglineBackgroundRef = useRef("");
  const {
    watch,
    control,
    register,
    clearErrors,
    formState: { errors }
  } = useFormContext();
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: "design.uspIcons"
  });

  // field watchers
  const backgroundImage = watch("design.backgroundImage");
  const companyLogo = watch("design.companyLogo");
  const partnerLogo = watch("design.partnerLogo");
  const companyLogoColor = watch("design.companyLogoBackground");
  const baseColor = watch("design.baseColor");
  const modernBackgroundOne = watch("design.modernBackgroundOne");
  const modernBackgroundTwo = watch("design.modernBackgroundTwo");
  const modernContrastColor = watch("design.modernContrastColor");
  const modernHeaderLabelsColor = watch("design.modernHeaderLabelsColor");
  const modernNextButtonTextColor = watch("design.modernNextButtonTextColor");
  const modernSubmitButtonTextColor = watch(
    "design.modernSubmitButtonTextColor"
  );
  const modernSubmitButtonHoverBackground = watch(
    "design.modernSubmitButtonHoverBackground"
  );
  const modernSubmitButtonBackground = watch(
    "design.modernSubmitButtonBackground"
  );
  const modernNextButtonBackground = watch("design.modernNextButtonBackground");
  const modernNextButtonHoverBackground = watch(
    "design.modernNextButtonHoverBackground"
  );
  const compLogoBgDisplay = watch("design.companyLogoBackgroundDisplay");
  const additionalTaglineColor = watch("design.additionalTaglineColor");
  const additionalTaglineBackground = watch(
    "design.additionalTaglineBackground"
  );
  const isModernFunnel = watch("funnelDesign") === FUNNEL_DESIGNS.MODERN;

  const parseResourceSrc = (resourceObj) =>
    resourceObj?.importUrl
      ? resourceObj.importUrl
      : resourceObj?.key
        ? `${process.env.REACT_APP_ASSET_CDN_URL}/${resourceObj.key}/${THUMBNAIL_TYPE.ANSWER_OPTION_ICON}`
        : "";
  const parsePreviewResourceSrc = (resourceObj) =>
    resourceObj?.importUrl
      ? resourceObj.importUrl
      : resourceObj?.key
        ? `${resourceObj.url}/${resourceObj.key}`
        : "";
  const addNewUsp = () => {
    append({
      id: `${fields.length + 1}`,
      label: "",
      icon: "",
      color: "",
      position: fields.length
    });
    if (errors?.design?.uspIcons) {
      clearErrors("design.uspIcons");
    }
  };

  // useEffects for color pickers references
  useEffect(() => {
    if (
      colorCodeRegex.test(companyLogoColor) &&
      companyLogoColor.length === 7 &&
      companyLogoColorPickerRef.current
    ) {
      companyLogoColorPickerRef.current.value = companyLogoColor;
    }
  }, [companyLogoColor]);
  useEffect(() => {
    if (
      colorCodeRegex.test(baseColor) &&
      baseColor.length === 7 &&
      baseColorPickerRef.current
    ) {
      baseColorPickerRef.current.value = baseColor;
    }
  }, [baseColor]);
  useEffect(() => {
    if (
      colorCodeRegex.test(modernBackgroundOne) &&
      modernBackgroundOne?.length === 7 &&
      modernBackgroundOneRef.current
    ) {
      modernBackgroundOneRef.current.value = modernBackgroundOne;
    }
  }, [modernBackgroundOne]);
  useEffect(() => {
    if (
      colorCodeRegex.test(modernBackgroundTwo) &&
      modernBackgroundTwo?.length === 7 &&
      modernBackgroundTwoRef.current
    ) {
      modernBackgroundTwoRef.current.value = modernBackgroundTwo;
    }
  }, [modernBackgroundTwo]);
  useEffect(() => {
    if (
      colorCodeRegex.test(modernContrastColor) &&
      modernContrastColor?.length === 7 &&
      modernContrastColorRef.current
    ) {
      modernContrastColorRef.current.value = modernContrastColor;
    }
  }, [modernContrastColor]);
  useEffect(() => {
    if (
      colorCodeRegex.test(modernHeaderLabelsColor) &&
      modernHeaderLabelsColor?.length === 7 &&
      modernHeaderLabelsColorRef.current
    ) {
      modernHeaderLabelsColorRef.current.value = modernHeaderLabelsColor;
    }
  }, [modernHeaderLabelsColor]);
  useEffect(() => {
    if (
      colorCodeRegex.test(modernNextButtonTextColor) &&
      modernNextButtonTextColor?.length === 7 &&
      modernNextButtonTextColorRef.current
    ) {
      modernNextButtonTextColorRef.current.value = modernNextButtonTextColor;
    }
  }, [modernNextButtonTextColor]);
  useEffect(() => {
    if (
      colorCodeRegex.test(modernSubmitButtonTextColor) &&
      modernSubmitButtonTextColor?.length === 7 &&
      modernSubmitButtonTextColorRef.current
    ) {
      modernSubmitButtonTextColorRef.current.value =
        modernSubmitButtonTextColor;
    }
  }, [modernSubmitButtonTextColor]);
  useEffect(() => {
    if (
      colorCodeRegex.test(modernSubmitButtonHoverBackground) &&
      modernSubmitButtonHoverBackground?.length === 7 &&
      modernSubmitButtonHoverBackgroundRef.current
    ) {
      modernSubmitButtonHoverBackgroundRef.current.value =
        modernSubmitButtonHoverBackground;
    }
  }, [modernSubmitButtonHoverBackground]);
  useEffect(() => {
    if (
      colorCodeRegex.test(modernSubmitButtonBackground) &&
      modernSubmitButtonBackground?.length === 7 &&
      modernSubmitButtonBackgroundRef.current
    ) {
      modernSubmitButtonBackgroundRef.current.value =
        modernSubmitButtonBackground;
    }
  }, [modernSubmitButtonBackground]);
  useEffect(() => {
    if (
      colorCodeRegex.test(modernNextButtonBackground) &&
      modernNextButtonBackground?.length === 7 &&
      modernNextButtonBackgroundRef.current
    ) {
      modernNextButtonBackgroundRef.current.value = modernNextButtonBackground;
    }
  }, [modernNextButtonBackground]);
  useEffect(() => {
    if (
      colorCodeRegex.test(modernNextButtonHoverBackground) &&
      modernNextButtonHoverBackground?.length === 7 &&
      modernNextButtonHoverBackgroundRef.current
    ) {
      modernNextButtonHoverBackgroundRef.current.value =
        modernNextButtonHoverBackground;
    }
  }, [modernNextButtonHoverBackground]);
  useEffect(() => {
    if (
      colorCodeRegex.test(additionalTaglineColor) &&
      additionalTaglineColor?.length === 7 &&
      additionalTaglineColorRef.current
    ) {
      additionalTaglineColorRef.current.value = additionalTaglineColor;
    }
  }, [additionalTaglineColor]);
  useEffect(() => {
    if (
      colorCodeRegex.test(additionalTaglineBackground) &&
      additionalTaglineBackground?.length === 7 &&
      additionalTaglineBackgroundRef.current
    ) {
      additionalTaglineBackgroundRef.current.value =
        additionalTaglineBackground;
    }
  }, [additionalTaglineBackground]);

  return (
    <div className="py-3 px-0">
      <FieldSet>
        <Legend>
          <h6 className="mb-0 fw-bold">{t("layout")}</h6>
        </Legend>
        <div className="px-0 mt-2">
          <Row>
            <Col>
              <div>
                <RadioInput
                  label={t("modern.selectFunnelDesign")}
                  firstOptLabel="Classic"
                  secondOptLabel="Modern"
                  name="funnelDesign"
                  firstValue={FUNNEL_DESIGNS.CLASSIC}
                  firstValueId={FUNNEL_DESIGNS.CLASSIC}
                  secondValue={FUNNEL_DESIGNS.MODERN}
                  secondValueId={FUNNEL_DESIGNS.MODERN}
                  register={register}
                />
              </div>
            </Col>
          </Row>
          {/* Layout images section */}
          <Collapse in={!isModernFunnel}>
            <Row>
              <Col xs={12} md={6}>
                <FileInput
                  label={t("backgroundImage")}
                  name="design.backgroundImage"
                  resourceId={resourceId}
                  parentFolderName={parentFolderName}
                  url={parseResourceSrc(backgroundImage)}
                  urlForPreview={parsePreviewResourceSrc(backgroundImage)}
                  asset={backgroundImage?.asset}
                  selectedCategory={selectedCategory}
                  tagName="backgroundimage"
                />
              </Col>
              <Col xs={12} md={6}>
                <FileInput
                  label="Partnerlogo"
                  name="design.partnerLogo"
                  resourceId={resourceId}
                  parentFolderName={parentFolderName}
                  url={parseResourceSrc(partnerLogo)}
                  urlForPreview={parsePreviewResourceSrc(partnerLogo)}
                  asset={partnerLogo?.asset}
                  selectedCategory={selectedCategory}
                  tagName="partnerlogo"
                  setDisplay
                />
              </Col>
            </Row>
          </Collapse>
          <Row>
            <Col xs={12} md={6}>
              <FileInput
                label={t("companyLogo")}
                name="design.companyLogo"
                resourceId={resourceId}
                parentFolderName={parentFolderName}
                url={parseResourceSrc(companyLogo)}
                urlForPreview={parsePreviewResourceSrc(companyLogo)}
                asset={companyLogo?.asset}
                selectedCategory={selectedCategory}
                tagName="companylogo"
              />
            </Col>
          </Row>

          {/* Layout color pickers section */}
          <Collapse in={isModernFunnel}>
            <Row>
              <Col xs={12} md={3} className="mb-4">
                <ColorPickerWithTextField
                  label={t("modern.backgroundColorAbove")}
                  fieldName="design.modernBackgroundOne"
                  fieldError={errors?.design?.modernBackgroundTwo}
                  fieldRef={modernBackgroundOneRef}
                  isInGroup
                  noSpacing
                  hideErrMsg
                />
              </Col>
              <Col xs={12} md={3} className="mb-4">
                <ColorPickerWithTextField
                  label={t("modern.backgroundColorBelow")}
                  fieldName="design.modernBackgroundTwo"
                  fieldError={errors?.design?.modernBackgroundTwo}
                  fieldRef={modernBackgroundTwoRef}
                  isInGroup
                  noSpacing
                  hideErrMsg
                />
              </Col>
              <Col xs={12} md={6} className="mb-4">
                <ColorPickerWithTextField
                  label={t("modern.headerLabelColor")}
                  fieldName="design.modernHeaderLabelsColor"
                  fieldError={errors?.design?.modernHeaderLabelsColor}
                  fieldRef={modernHeaderLabelsColorRef}
                  isInGroup
                  noSpacing
                  hideErrMsg
                />
              </Col>
              <Col xs={12} md={6} className="mb-4">
                <ColorPickerWithTextField
                  label={t("modern.contrastColor")}
                  fieldName="design.modernContrastColor"
                  fieldError={errors?.design?.modernContrastColor}
                  fieldRef={modernContrastColorRef}
                  isInGroup
                  noSpacing
                  hideErrMsg
                />
              </Col>
              <Col xs={12} md={3} className="mb-4">
                <ColorPickerWithTextField
                  label={t("modern.taglineColor")}
                  fieldName="design.additionalTaglineColor"
                  fieldError={errors?.design?.additionalTaglineColor}
                  fieldRef={additionalTaglineColorRef}
                  isInGroup
                  noSpacing
                  hideErrMsg
                />
              </Col>
              <Col xs={12} md={3} className="mb-4">
                <ColorPickerWithTextField
                  label={t("modern.taglineBackground")}
                  fieldName="design.additionalTaglineBackground"
                  fieldError={errors?.design?.additionalTaglineBackground}
                  fieldRef={additionalTaglineBackgroundRef}
                  isInGroup
                  noSpacing
                  hideErrMsg
                />
              </Col>

              {/* modern next buttons */}
              <div className="fw-bold text-decoration-underline">
                {t("modern.nextButton.heading")}
              </div>
              <Col xs={12} md={3} className="mb-4">
                <TextField
                  label={t("modern.label")}
                  placeholder={`${t("common.enter")} ${t("modern.label")}`}
                  type="text"
                  name="design.modernNextButtonText"
                  errors={errors?.design?.modernNextButtonText}
                  register={register}
                  noSpacing
                />
              </Col>
              <Col xs={12} md={3} className="mb-4">
                <ColorPickerWithTextField
                  label={t("modern.nextButton.btnTextColor")}
                  fieldName="design.modernNextButtonTextColor"
                  fieldError={errors?.design?.modernNextButtonTextColor}
                  fieldRef={modernNextButtonTextColorRef}
                  isInGroup
                  noSpacing
                  hideErrMsg
                />
              </Col>
              <Col xs={12} md={3} className="mb-4">
                <ColorPickerWithTextField
                  label={t("modern.nextButton.btnBackgroundColor")}
                  fieldName="design.modernNextButtonBackground"
                  fieldError={errors?.design?.modernNextButtonBackground}
                  fieldRef={modernNextButtonBackgroundRef}
                  isInGroup
                  noSpacing
                  hideErrMsg
                />
              </Col>
              <Col xs={12} md={3} className="mb-4">
                <ColorPickerWithTextField
                  label={t("modern.nextButton.btnHoverBackground")}
                  fieldName="design.modernNextButtonHoverBackground"
                  fieldError={errors?.design?.modernNextButtonHoverBackground}
                  fieldRef={modernNextButtonHoverBackgroundRef}
                  isInGroup
                  noSpacing
                  hideErrMsg
                />
              </Col>

              {/* modern submit button */}
              <div className="fw-bold text-decoration-underline">
                {t("modern.callToActionButtonHeading")}
              </div>
              <Col xs={12} md={3} className="mb-4">
                <TextField
                  label={t("modern.label")}
                  placeholder={`${t("common.enter")} ${t("modern.label")}`}
                  type="text"
                  name="design.modernSubmitButtonText"
                  errors={errors?.design?.modernSubmitButtonText}
                  register={register}
                  noSpacing
                />
              </Col>
              <Col xs={12} md={3} className="mb-4">
                <ColorPickerWithTextField
                  label={t("modern.nextButton.btnTextColor")}
                  fieldName="design.modernSubmitButtonTextColor"
                  fieldError={errors?.design?.modernSubmitButtonTextColor}
                  fieldRef={modernSubmitButtonTextColorRef}
                  isInGroup
                  noSpacing
                  hideErrMsg
                />
              </Col>
              <Col xs={12} md={3} className="mb-4">
                <ColorPickerWithTextField
                  label={t("modern.nextButton.btnBackgroundColor")}
                  fieldName="design.modernSubmitButtonBackground"
                  fieldError={errors?.design?.modernSubmitButtonBackground}
                  fieldRef={modernSubmitButtonBackgroundRef}
                  isInGroup
                  noSpacing
                  hideErrMsg
                />
              </Col>
              <Col xs={12} md={3} className="mb-4">
                <ColorPickerWithTextField
                  label={t("modern.nextButton.btnHoverBackground")}
                  fieldName="design.modernSubmitButtonHoverBackground"
                  fieldError={errors?.design?.modernSubmitButtonHoverBackground}
                  fieldRef={modernSubmitButtonHoverBackgroundRef}
                  isInGroup
                  noSpacing
                  hideErrMsg
                />
              </Col>
            </Row>
          </Collapse>
          <Row>
            <Collapse in={!isModernFunnel}>
              <Col xs={12} md={6}>
                <ColorPickerWithTextField
                  label={t("companyLogoBG")}
                  fieldName="design.companyLogoBackground"
                  fieldError={errors?.design?.companyLogoBackground}
                  fieldRef={companyLogoColorPickerRef}
                  fieldWatcher={compLogoBgDisplay}
                  isDisabled={Boolean(!compLogoBgDisplay)}
                  setDisplay
                  isInGroup
                  noSpacing
                  hideErrMsg
                />
              </Col>
            </Collapse>
            <Col xs={12} md={6} className="mb-4">
              {/* propert for set progress bar color and loader color */}
              <ColorPickerWithTextField
                label={t("baseColor")}
                fieldName="design.baseColor"
                fieldError={errors?.design?.baseColor}
                fieldRef={baseColorPickerRef}
                isInGroup
                noSpacing
                hideErrMsg
              />
            </Col>
          </Row>

          <Collapse in={isModernFunnel}>
            <div>
              {/* Layout modern header section */}
              <Row>
                <Col xs={12} md={3} className="mb-4">
                  <TextField
                    label={t("modern.callLabel")}
                    placeholder="Add call label"
                    type="text"
                    name="design.modernHeaderCallLabel"
                    errors={errors?.design?.modernHeaderCallLabel}
                    register={register}
                    noSpacing
                  />
                </Col>
                <Col xs={12} md={3} className="mb-4">
                  <TextField
                    label={t("modern.telephoneNumber")}
                    placeholder="Add number"
                    type="number"
                    name="design.modernHeaderPhoneNumber"
                    errors={errors?.design?.modernHeaderPhoneNumber}
                    register={register}
                    blurOnScroll
                    noSpacing
                  />
                </Col>
                <Col xs={12} md={3} className="mb-4">
                  <TextField
                    label={t("modern.emailAddress")}
                    placeholder="Add email address"
                    type="email"
                    name="design.modernHeaderEmailAddress"
                    errors={errors?.design?.modernHeaderEmailAddress}
                    register={register}
                    noSpacing
                  />
                </Col>
                <Col xs={12} md={3} className="mb-4">
                  <TextField
                    label={t("modern.headline")}
                    placeholder="Add additional page headline"
                    type="text"
                    name="design.modernHeadline"
                    errors={errors?.design?.modernHeadline}
                    register={register}
                    noSpacing
                  />
                </Col>
              </Row>
              {/* Layout modern page section */}
              <Row>
                <Col xs={12} md={6} className="mb-4">
                  <TextField
                    label={"Funnel Headline"}
                    placeholder="Add additional page headline"
                    type="text"
                    name="design.modernFunnelHeadline"
                    errors={errors?.design?.modernFunnelHeadline}
                    register={register}
                    noSpacing
                  />
                </Col>
                <Col xs={12} md={3} className="mb-4">
                  <TextField
                    label={t("modern.taglineOne")}
                    placeholder="Add additional tagline one"
                    type="text"
                    name="design.additionalTaglineLabelOne"
                    errors={errors?.design?.additionalTaglineLabelOne}
                    register={register}
                    noSpacing
                  />
                </Col>
                <Col xs={12} md={3} className="mb-4">
                  <TextField
                    label={t("modern.taglineTwo")}
                    placeholder="Add additional tagline two"
                    type="text"
                    name="design.additionalTaglineLabelTwo"
                    errors={errors?.design?.additionalTaglineLabelTwo}
                    register={register}
                    noSpacing
                  />
                </Col>
              </Row>
              <Row>
                <div className="fw-bold text-decoration-underline">
                  Form Info:
                </div>
                <Col xs={12} md={6} className="mb-4">
                  <TextField
                    label={t("modern.formHeading")}
                    placeholder={t("common.enter")}
                    type="text"
                    name="design.modernFormHeading"
                    errors={errors?.design?.modernFormHeading}
                    register={register}
                    noSpacing
                  />
                </Col>
                <Col xs={12} md={6} className="mb-4">
                  <TextField
                    label={t("modern.infoLabelOne")}
                    placeholder={t("common.enter")}
                    type="text"
                    name="design.modernFormInfoLabelOne"
                    errors={errors?.design?.modernFormInfoLabelOne}
                    register={register}
                    noSpacing
                  />
                </Col>
                <Col xs={12} md={6} className="mb-4">
                  <TextField
                    label={t("modern.infoLabelTwo")}
                    placeholder={t("common.enter")}
                    type="text"
                    name="design.modernFormInfoLabelTwo"
                    errors={errors?.design?.modernFormInfoLabelTwo}
                    register={register}
                    noSpacing
                  />
                </Col>
                <Col xs={12} md={6} className="mb-4">
                  <TextField
                    label={t("modern.infoLabelThree")}
                    placeholder={t("common.enter")}
                    type="text"
                    name="design.modernFormInfoLabelThree"
                    errors={errors?.design?.modernFormInfoLabelThree}
                    register={register}
                    noSpacing
                  />
                </Col>
              </Row>
            </div>
          </Collapse>

          {/* Layout companyLogoScale and footerTextColor section */}
          <Row>
            <Col xs={12} md={6} className="mb-4">
              <TextField
                label={t("companyLogoScale")}
                placeholder="100%"
                type="number"
                name="design.companyLogoScale"
                register={register}
                blurOnScroll
                noSpacing
              />
            </Col>
            <Collapse in={!isModernFunnel}>
              <Col xs={12} md={6} className="mb-4">
                <RadioInput
                  label={t("footerTxtColor")}
                  firstOptLabel={t("footerColors.black")}
                  secondOptLabel={t("footerColors.white")}
                  thirdOptLabel={t("footerColors.whiteShadow")}
                  name="design.footerForeground"
                  firstValue={FOOTER_FOREGROUND.BLACK}
                  firstValueId={FOOTER_FOREGROUND.BLACK}
                  secondValue={FOOTER_FOREGROUND.WHITE}
                  secondValueId={FOOTER_FOREGROUND.WHITE}
                  thirdValue={FOOTER_FOREGROUND.SHADOWED}
                  thirdValueId={FOOTER_FOREGROUND.SHADOWED}
                  register={register}
                  labelSpacing
                />
              </Col>
            </Collapse>
          </Row>
          <Row>
            <Col
              xs={12}
              md={6}
              className="d-flex flex-column justify-content-center"
            >
              <div
                className={`d-flex ${
                  watch("design.policyDisplay") ? "" : "mb-4"
                }`}
              >
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {`Policy will ${
                        watch("design.policyDisplay") ? "" : "not"
                      } be shown`}
                    </Tooltip>
                  }
                >
                  <Form.Check
                    type="switch"
                    role="button"
                    label="Display Policy Section"
                    {...register("design.policyDisplay")}
                    reverse
                  />
                </OverlayTrigger>
              </div>
              <Collapse in={watch("design.policyDisplay")}>
                <div>
                  <TextArea
                    register={register}
                    name="design.policyText"
                    placeholder="Enter text for display as policy text"
                    errors={errors?.design?.policyText}
                  />
                </div>
              </Collapse>
            </Col>
          </Row>

          {/* Layout USP icons section */}
          <Collapse in={isModernFunnel}>
            <div>
              <Row>
                <Col xs={12}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div
                      className={`fw-bold ${
                        errors?.design?.uspIcons ? "text-danger" : ""
                      }`}
                    >
                      {`${t("modern.uspLabel")} 
                  ${errors?.design?.uspIcons ? "*" : ""}`}
                    </div>
                    <div className="ms-3">
                      <Button
                        className="w-100"
                        onClick={addNewUsp}
                        disabled={fields?.length === 5}
                      >
                        {t("modern.addNewUspLabel")}
                        {fields.length ? (
                          <Badge bg="secondary" className="rounded-circle ms-2">
                            {fields.length}
                          </Badge>
                        ) : (
                          ""
                        )}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
              {fields.length ? (
                <Row>
                  <Col xs={12}>
                    <USPAccordion move={move} remove={remove} fields={fields} />
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </div>
          </Collapse>
        </div>
      </FieldSet>
    </div>
  );
};
export default LayoutSection;
