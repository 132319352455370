const black = { color: "#ffffff", background: "#000000" };
const darkGray = { color: "#ffffff", background: "#808080" };
const red = { color: "#f5222d", background: "#fff1f0", border: "#ffa39e" };
const blue = { color: "#1890ff", background: "#e6f7ff", border: "#91d5ff" };
const green = { color: "#52c41a", background: "#f6ffed", border: "#b7eb8f" };
const orange = { color: "#fa8c16", background: "#fff7e6", border: "#ffd591" };

// lead & applicant stages
export const stagesHash = [
  { title: "Noch nicht kontaktiert", value: "new", ...darkGray },
  { title: "Kontaktversuch", value: "contact_attempt", ...orange },
  { title: "Warte auf Kundeninformationen", value: "appointment_failed" },
  { title: "Termin vereinbart", value: "appointment_agreed", ...blue },
  { title: "Termin erfolgt", value: "appointment_is_done" },
  { title: "Angebot versendet", value: "offer_sent" },
  { title: "Auftrag", value: "assignment", ...green },
  { title: "Nicht geeignet", value: "not_suitable", ...red },
  { title: "Archiviert", value: "archived", ...black },
  { title: "Alle", value: "all" },
  { title: "Gelöscht", value: "deleted" },
  {
    title: "Bewerbung eingegangen",
    value: "application_received",
    ...darkGray
  },
  { title: "Telefongespräch", value: "phone_interview", ...blue },
  { title: "Bewerbungsgespräch", value: "interview" },
  { title: "Bewertung", value: "screening" },
  { title: "Angebot", value: "offer" },
  { title: "Eingestellt", value: "hired", ...green }
];
